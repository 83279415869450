import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Box, CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import './ForexNews.css'; // Ensure you have this CSS for custom styling
import DashboardNavbar from '../../layouts/dashboard/DashboardNavbar';
import NavbarComponent from '../../components/NewNav';
import UserDashboardNavbar from '../../layouts/dashboard copy/DashboardNavbar';
import About from '../../components/About';

const ForexNews = () => {
  const [newsEvents, setNewsEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch economic calendar data
  const fetchEconomicData = async () => {
    try {
      const today = new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const response = await fetch(`https://traderz.fund:5000/api/economic-calendar?date_time=${encodeURIComponent(today)}`);
      const data = await response.json();
      setNewsEvents(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching economic data:', error);
      setLoading(false);
    }
  };

  // Fetch economic data on component mount
  useEffect(() => {
    fetchEconomicData();
    setTimeout(fetchEconomicData, 600000); 
  }, []);

  const formatDateTime = (dateTime) => {
    const [date, time] = dateTime.split(', ');
    return { date, time };
  };

  const getColorForValue = (value) => {
    if (!value) return null;
    const numValue = parseFloat(value);
    return numValue < 0 ? 'red' : 'green';
  };

  const getFlagUrl = (country) => {
    const flags = {
      'Australia': 'https://flagsapi.com/AU/flat/64.png',
      'Finland': 'https://flagsapi.com/FI/flat/64.png',
      'Spain': 'https://flagsapi.com/ES/flat/64.png',
      'China': 'https://flagsapi.com/CN/flat/64.png',
      'Ireland': 'https://flagsapi.com/IE/flat/64.png',
      'Portugal': 'https://flagsapi.com/PT/flat/64.png',
      'Canada': 'https://flagsapi.com/CA/flat/64.png',
      'New Zealand': 'https://flagsapi.com/NZ/flat/64.png',
      'Euro Area': 'https://flagsapi.com/ES/flat/64.png',
      'United Kingdom': 'https://flagsapi.com/GB/flat/64.png',
      'France': 'https://flagsapi.com/FR/flat/64.png',
      'Italy': 'https://flagsapi.com/IT/flat/64.png',
      'United States': 'https://flagsapi.com/US/flat/64.png',
      'Japan': 'https://flagsapi.com/JP/flat/64.png',
      'Germany': 'https://flagsapi.com/DE/flat/64.png',
      'Slovakia': 'https://flagsapi.com/SK/flat/64.png',
      'El Salvador': 'https://flagsapi.com/SV/flat/64.png',
      'Slovakia': 'https://flagsapi.com/SK/flat/64.png',
     
    };

    return flags[country] || '';
  };

  const isUserLoggedIn = localStorage.getItem('name') && localStorage.getItem('email');
  const isUserAdmin = localStorage.getItem('role');

  return (
    <>
      {!isUserLoggedIn && (
      <>
        <NavbarComponent />
      </>
    )}

    {isUserLoggedIn && isUserAdmin === 'admin' && (
      <><DashboardNavbar /> <br /><br /><br /><br /><br /><br />
      </>
    )}

    {isUserLoggedIn && isUserAdmin !== 'admin' && (
      <>
        <UserDashboardNavbar /><br /><br /><br /><br /><br /><br />
      </>
    )}
    <Box className="forex-news-container p-0 m-0">
      <Typography variant="h4" className="forex-news-title  mt-5" gutterBottom>
        Forex Economic Calendar
      </Typography>

      {loading ? (
        <Box className="loading-indicator">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="table-wrapper mb-5 mt-5">
          <TableContainer component={Paper} className="forex-news-table">
            <Table aria-label="forex news table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell>Previous</TableCell>
                  <TableCell>Consensus</TableCell>
                  <TableCell>Actual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newsEvents.map((event, index) => {
                  const { date, time } = formatDateTime(event.date_time);
                  const flagUrl = getFlagUrl(event.country);

                  return (
                    <TableRow key={index} className="hoverable-row">
                      <TableCell>{date}</TableCell>
                      <TableCell>{time}</TableCell>
                      <TableCell>
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <img src={flagUrl} alt={`-`} style={{ width: '24px', marginRight: '8px' }} />
    <span>{event.country} ({event.currency})</span>
  </span>
</TableCell>

                      <TableCell>{event.event}</TableCell>
                      <TableCell>{event.impact}</TableCell>
                      <TableCell>{event.previous || '-'}</TableCell>
                      <TableCell>{event.consensus || '-'}</TableCell>
                      <TableCell style={{ color: getColorForValue(event.actual) }}>
                        {event.actual || '-'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

    <About />
    </Box>
    </>
  );
};

export default ForexNews;
