import React, { useEffect, useState, useContext } from 'react';
import Divider from './Divider';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BotContext } from './BotContext/BotContext';

const Product = () => {
  const [products, setProducts] = useState([]);
  const { setSelectedBot } = useContext(BotContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://traderz.fund:3060/settings/bots');
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="container mx-auto">
      <h1 style={{color:'#234264'}} className='text-3xl font-bold text-center m-5' id='products'>Fx Trading Bots</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {products?.map((product) => (
          <div key={product.id} className="bg-white shadow-md rounded-md overflow-hidden transform transition-transform hover:scale-105">
            <img className="h-48 w-full object-cover" src={product.img} alt={'bot image'} />
            <div className="p-4">
              <h1 className="text-lg font-semibold text-primary text-center">{product.name}</h1>
              <Divider />
              <div
                className="mt-2 text-sm text-gray-600"
                dangerouslySetInnerHTML={{ __html: product.description || 'No description available' }}
              />
              {product.Packages.length > 0 && (
                <div>
                  <h2 className="mt-2 text-xs font-semibold">Packages:</h2>
                  <ul className="list-disc list-inside text-xs">
                    {product.Packages.map((pkg) => (
                      <li key={pkg.id} className="text-gray-600">
                        {pkg.name}: ${pkg.price}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="mt-4 flex justify-between items-center">
                {/* <Link
                  to={`/cryptobot/${product.slug}`}
                  className="text-primary hover:text-primary-dark font-semibold"
                  onClick={() => {
                    setSelectedBot(product);
                    window.localStorage.setItem('selectedBot', JSON.stringify(product));
                  }}
                >
                  Learn More
                </Link> */}
               <Link
                  to={`/bot/${product.slug}`} onClick={() => {
                    setSelectedBot(product);
                    window.localStorage.setItem('selectedBot', JSON.stringify(product));
                  }}> <button className="bg-primary ms-auto text-white px-3 py-1 rounded-md hover:bg-primary-dark focus:outline-none">
                  Rent Now!
                </button></Link>
              </div>
            </div>
          </div>
          
        ))}
      </div>
    </div>
  );
};

export default Product;
