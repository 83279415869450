import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast, ToastContainer } from 'react-toastify';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 50px;

  @media (min-width: 768px) {
    padding: 40px;
    margin-top: 100px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 15px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CryptobotDetails = () => {
  const { id, name ,slug} = useParams();
  const [strategy, setStrategy] = useState('');
  const [features, setFeatures] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: ` ${token}` };
        const response = await axios.get(`https://traderz.fund:3060/settings/bots/${slug}`, { headers });
        const { strategy, spec, video } = response.data;
        setStrategy(strategy || '');
        setFeatures(spec || '');
        setYoutubeLink(video || '');
      } catch (error) {
        toast.error('Failed to fetch Bot details. Please try again.');
      }
    };

    fetchBotDetails();
  }, [id]);

  const handleSave = async () => {
    // Validate form fields
    if (!strategy || !features || !youtubeLink) {
      toast.error('All fields are required.');
      return;
    }
    const token = localStorage.getItem('token');
    const botData = {
      strategy,
      spec: features,
      video: youtubeLink,
      token:token
    };

   
    const headers = { Authorization: ` ${token}` };

    try {
      await axios.put(`https://traderz.fund:3060/settings/bots/media/${id}`, botData, {
        headers:{
          'Content-Type': 'application/json',
                Authorization: `${token}`
        }
    }, );
      toast.success('Settings saved successfully!');
    } catch (error) {
      toast.error('Failed to save Bot. Please try again.');
    }
  };

  return (
    <>
      <Container>
        <ToastContainer/>
        <h1 className="text-2xl font-bold mb-5">{name}</h1>
        <Form>
          <Label htmlFor="strategy">Strategy</Label>
          <ReactQuill
            theme="snow"
            value={strategy}
            onChange={setStrategy}
            placeholder="Enter the strategy"
            style={{ marginBottom: '20px' }}
          />

          <Label htmlFor="features">Features</Label>
          <ReactQuill
            theme="snow"
            value={features}
            onChange={setFeatures}
            placeholder="Enter the features"
            style={{ marginBottom: '20px' }}
          />

          <Label htmlFor="youtubeLink">YouTube Video Link</Label>
          <Input
            type="text"
            id="youtubeLink"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
            placeholder="Enter the YouTube video link"
          />

          <Button type="button" onClick={handleSave}>
            Save
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default CryptobotDetails;
