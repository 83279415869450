import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #f4f4f4;
  text-align: left;
  border-bottom: 2px solid #ddd;
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #f9f9f9;
  }
`;

const Select = styled.select`
  padding: 5px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
`;

const FileDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
`;

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch('https://traderz.fund:3060/settings/bots/subscribtions/all', {
          headers: {
            'Authorization': ` ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch subscriptions.');
        }

        const data = await response.json();
        setSubscriptions(data);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('No token found. Please log in.');
      return;
    }

    try {
      const response = await fetch(`https://traderz.fund:3060/settings/bots/subscriptions/edit/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ` ${token}`,
        },
        body: JSON.stringify({ paymentStatus: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update payment status.');
      }

      const updatedSubscription = await response.json();
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((subscription) =>
          subscription.id === id ? { ...subscription, paymentStatus: newStatus } : subscription
        )
      );
      toast.success('Payment status updated successfully.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadClick = (subscription) => {
    setSelectedSubscription(subscription);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSubscription(null);
    setFiles([]);
    setUploadProgress(0);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    setUploadProgress(0); // Reset progress on new file drop
  }, []);

  const handleFileUpload = async () => {
    if (files.length === 0) {
      toast.error('Please select a file to upload.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('No token found. Please log in.');
      return;
    }

    const formData = new FormData();
    formData.append('download_file', files[0]); // Assuming only one file is allowed

    try {
      const response = await fetch(`https://traderz.fund:3060/settings/bots/subscriptions/downloadfile/edit/${selectedSubscription.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const responseText = await response.text(); // Read the response text for more details
        console.error('Failed to upload file:', responseText);
        throw new Error('Failed to upload file.');
      }

      // Simulate upload progress (as a real-world case might involve progress events)
      setUploadProgress(100);

      toast.success('File uploaded successfully!');
      handleClose();
    } catch (error) {
      console.error('Upload error:', error.message);
      toast.error(error.message);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!');
    }, () => {
      toast.error('Failed to copy.');
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container-fluid '>
      <Title>Users Subscriptions</Title>
      <Table>
        <thead>
          <tr>
            <TableHeader className='text-sm'>ID</TableHeader>
            <TableHeader className='text-sm'>User Id</TableHeader>
            <TableHeader className='text-sm'>User Name</TableHeader>
            <TableHeader className='text-sm'>Bot ID</TableHeader>
            <TableHeader className='text-sm'>Account Number</TableHeader>
            <TableHeader className='text-sm'>Payment Method</TableHeader>
            <TableHeader className='text-sm'>Paid Price</TableHeader>
            <TableHeader className='text-sm'>Payment Status</TableHeader>
            <TableHeader className='text-sm'>Path Bill</TableHeader>
            <TableHeader className='text-sm'>Code</TableHeader>
            {/* <TableHeader className='text-sm'>Upload Expert</TableHeader> */}
            <TableHeader className='text-sm'>Sub-Statuc</TableHeader>
            <TableHeader className='text-sm'>Created At</TableHeader>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <TableRow key={subscription.id}>
              <TableCell>{subscription.id}</TableCell>
              <TableCell 
                className='text-danger' 
                onClick={() => copyToClipboard(subscription.user_id)}
                style={{ cursor: 'pointer', color: '#007bff' }}
              >
                {subscription?.user_id}
              </TableCell>
              <TableCell>{subscription?.User?.name}</TableCell>
              <TableCell 
                className='text-danger' 
                onClick={() => copyToClipboard(subscription.bot_id)}
                style={{ cursor: 'pointer', color: '#007bff' }}
              >
                {subscription.bot_id}
              </TableCell>
              <TableCell 
                className='text-danger' 
                onClick={() => copyToClipboard(subscription.account_number)}
                style={{ cursor: 'pointer', color: '#007bff' }}
              >
                {subscription.account_number}
              </TableCell>
              <TableCell>{subscription.paymentMethod}</TableCell>
              <TableCell>{subscription.paid_price}</TableCell>
              <TableCell>
                <Select
                  value={subscription.paymentStatus}
                  onChange={(e) => handleStatusChange(subscription.id, e.target.value)}
                >
                  <option value="paid">Paid</option>
                  <option value="pending">Pending</option>
                  <option value="unpaid">Unpaid</option>
                </Select>
              </TableCell>
              <TableCell>
                {subscription.path_bill ? (
                  <button onClick={() => window.open(subscription.path_bill, '_blank')}>
                    Open Image
                  </button>
                ) : (
                  'No Image'
                )}
              </TableCell>
              <TableCell>{subscription.code}</TableCell>
           
              {/* <TableCell>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleUploadClick(subscription)} 
                  disabled={subscription.paymentStatus !== 'paid'}
                >
                  Submit
                </Button>
              </TableCell> */}
              <TableCell>{subscription.paymentStatus==="paid"?<b className='text-success'>Active</b>:<b className='text-dangernpm'>Inactive</b>}</TableCell>
              <TableCell>{new Date(subscription.createdAt).toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>

      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Upload Expert</DialogTitle> */}
        <DialogContent>
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag & drop some files here, or click to select files</p>
          </DropzoneContainer>
          {files.length > 0 && (
            <FileDetails>
              <p>Selected file: {files[0].name}</p>
            </FileDetails>
          )}
          {uploadProgress > 0 && (
            <ProgressBarContainer>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </ProgressBarContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFileUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Subscriptions;
