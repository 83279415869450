import React, { useState } from 'react';
import {
  TextField,
  Switch,
  Button as MuiButton,
  FormControlLabel,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ImagePreview = styled('img')({
  width: '100%',
  height: 'auto',
  marginTop: '10px',
  borderRadius: '8px',
});

const NewBotPage = () => {
  const [value, setValue] = useState('');
  const [botData, setBotData] = useState({
    botName: '',
    isActive: false,
    botBrief: value,
    botImage: null,
    packages: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBotData({ ...botData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBotData({ ...botData, botImage: file });
  };

  const handleSwitchChange = (e) => {
    setBotData({ ...botData, isActive: e.target.checked });
  };

  const handleAddPackage = () => {
    setBotData({
      ...botData,
      packages: [...botData.packages, { packageName: '', features: [''], price: '' }],
    });
  };

  const handlePackageChange = (index, updatedPackage) => {
    const updatedPackages = [...botData.packages];
    updatedPackages[index] = updatedPackage;
    setBotData({ ...botData, packages: updatedPackages });
  };

  const handleRemovePackage = (index) => {
    const updatedPackages = [...botData.packages];
    updatedPackages.splice(index, 1);
    setBotData({ ...botData, packages: updatedPackages });
  };
  const handleFeatureChange = (pkgIndex, featureIndex, value) => {
    const updatedPackages = [...botData.packages];
    const updatedFeatures = updatedPackages[pkgIndex].features.map((feature, index) => 
      index === featureIndex ? { name: value } : feature
    );
    updatedPackages[pkgIndex].features = updatedFeatures;
    setBotData({ ...botData, packages: updatedPackages });
  };
  
  const handleAddFeature = (pkgIndex) => {
    const updatedPackages = [...botData.packages];
    updatedPackages[pkgIndex].features.push({ name: `Feature ${updatedPackages[pkgIndex].features.length + 1}` });
    setBotData({ ...botData, packages: updatedPackages });
  };
  
  const handleRemoveFeature = (pkgIndex, featureIndex) => {
    const updatedPackages = [...botData.packages];
    updatedPackages[pkgIndex].features.splice(featureIndex, 1);
    setBotData({ ...botData, packages: updatedPackages });
  };
  

  const navigate = useNavigate();
  const SaveNewBot = async () => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
  
    try {
      const formData = new FormData();
      formData.append('botName', botData.botName);
      formData.append('isActive', botData.isActive);
      formData.append('botBrief', botData.botBrief); // Ensure this line is correctly included
      formData.append('spec', botData.spec || ''); 
      formData.append('video', botData.video || ''); 
      formData.append('strategy', botData.strategy || ''); 
      formData.append('botImage', botData.botImage); 
  
      botData.packages.forEach((pkg, index) => {
        formData.append(`packages[${index}].packageName`, pkg.packageName);
        formData.append(`packages[${index}].price`, pkg.price);
        pkg.features.forEach((feature, featureIndex) => {
          formData.append(`packages[${index}].features[${featureIndex}].name`, feature.name);
        });
      });
      for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);  
      }
      
      await axios.post('https://traderz.fund:3060/settings/bots/new', formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Bot saved successfully!');
      navigate('/dashboard/bots');
    } catch (error) {
      toast.error('Failed to save bot. Please try again.');
    }
  };
  

  const handleSubmit = () => {
    SaveNewBot();
  };


  const handleQuillChange = (content) => {
    setBotData({ ...botData, botBrief: content });
  };
  

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Add New Bot
      </Typography>
      <TextField
        name="botName"
        label="Bot Name"
        value={botData.botName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={<Switch checked={botData.isActive} onChange={handleSwitchChange} />}
        label="Active"
      />
    <ReactQuill
  theme="snow"
  value={botData.botBrief}
  onChange={handleQuillChange}
/>

      <Form>
        <Form.Group controlId="formFile">
          <Form.Label>Upload Image</Form.Label>
          <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
        </Form.Group>
      </Form>
      <Typography variant="h6" component="h2" gutterBottom>
        Packages
      </Typography>
      {botData.packages.map((pkg, pkgIndex) => (
  <Card variant="outlined" sx={{ mb: 2 }} key={pkgIndex}>
    <CardContent>
      <TextField
        name="packageName"
        label="Package Name"
        value={pkg.packageName}
        onChange={(e) =>
          handlePackageChange(pkgIndex, {
            ...pkg,
            packageName: e.target.value,
          })
        }
        fullWidth
        margin="normal"
      />
      <TextField
        name="price"
        label="Price"
        type="text"
        value={pkg.price}
        onChange={(e) =>
          handlePackageChange(pkgIndex, {
            ...pkg,
            price: e.target.value,
          })
        }
        fullWidth
        margin="normal"
      />
      <Typography variant="h6" component="h3" gutterBottom>
        Features
      </Typography>
      {pkg.features.map((feature, featureIndex) => (
        <Grid container spacing={1} key={featureIndex}>
          <Grid item xs={10}>
            <TextField
              value={feature.name}
              onChange={(e) =>
                handleFeatureChange(pkgIndex, featureIndex, e.target.value)
              }
              fullWidth
              margin="normal"
              label={`Feature ${featureIndex + 1}`}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              color="error"
              onClick={() => handleRemoveFeature(pkgIndex, featureIndex)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <MuiButton
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => handleAddFeature(pkgIndex)}
        sx={{ mt: 2 }}
      >
        Add Feature
      </MuiButton>
    </CardContent>
    <CardActions>
      <IconButton color="error" onClick={() => handleRemovePackage(pkgIndex)}>
        <DeleteIcon />
      </IconButton>
    </CardActions>
  </Card>
))}

      <MuiButton
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddPackage}
        sx={{ mb: 2 }}
      >
        Add Package
      </MuiButton>
      <MuiButton variant="contained" color="primary" fullWidth onClick={handleSubmit}>
        Save Bot
      </MuiButton>
    </Container>
  );
};

export default NewBotPage;
