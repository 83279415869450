import { useRoutes } from 'react-router-dom';
import PrivateRoute from './components/auth/PrivateRoute';
import DashboardLayout from './layouts/dashboard';
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Home from './pages/Home';
import Settings from './pages/adminpages/Settings';
import NewBotPage from './pages/adminpages/NewBotPage';
import BotDetails from './pages/BotDetails';
import Cryptobot from './pages/BotDetails';
import Checkout from './pages/checkout';
import CryptobotDetails from './pages/adminpages/CryptoBotDetails';
import OpenAccount from './pages/adminpages/OpenAccount';
import Subscriptions from './pages/adminpages/Subscriptions';
import UserDashboardLayout from './layouts/dashboard copy';
import Profile from './pages/Profile';
import Subscription from './pages/Subscribtions';
import VerfyEmail from './pages/Verify';
import ResetPassword from './pages/ForgotPassword';
import ResetPasswordWthToken from './pages/ResetPasswordWthToken';
import MainPage from './Home/mainpage'
import UserPrivateRoute from './components/auth/UserPrivateRoute';
import UserOpenAccount from './pages/UserOpenAccount';
import UpdateBot from './pages/adminpages/UpdateBot';
import BonusRegister from './pages/BonusRegister';
import ForexNews from './pages/forexnews/ForexNews';
import ExpertManager from './pages/adminpages/ExpertManager';
export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { 
          path: 'app', 
          element: (
            <PrivateRoute>
              <DashboardApp />
            </PrivateRoute>
          ),
        },
        { 
          path: 'subscriptions', 
          element: (
            <PrivateRoute>
              <Subscriptions />
            </PrivateRoute>
          ),
        },
        { 
          path: 'user', 
          element: (
            <PrivateRoute>
              <User />
            </PrivateRoute>
          ),
        },
        { 
          path: 'open-account', 
          element: (
            <PrivateRoute>
              <OpenAccount />
            </PrivateRoute>
          ),
        },

        { 
          path: 'payments', 
          element: (
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          ),
        },
        

        { 
          path: 'newbot', 
          element: (
            <PrivateRoute>
              <NewBotPage />
            </PrivateRoute>
          ),
        },
        { 
          path: 'products', 
          element: (
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          ),
        },
        { 
          path: 'bots', 
          element: (
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          ),
        },
        ,
        { 
          path: 'expert-manager', 
          element: (
            <PrivateRoute>
              <ExpertManager/>
            </PrivateRoute>
          ),
        },
        { 
          path: 'bots/update/:slug', 
          element: (
            <PrivateRoute>
              <UpdateBot />
            </PrivateRoute>
          ),
        },
        { 
          path: 'blog', 
          element: (
            <PrivateRoute>
              <Blog />
            </PrivateRoute>
          ),
        },
        {
          path: 'cryptobot/:id/:slug',
          element: ( <PrivateRoute><CryptobotDetails/></PrivateRoute> ),
        },
      ],
    },
    { path: '/dashboard/user',
      element: <UserDashboardLayout />,
      children: [
        { 
          path: 'statistics', 
          element: (
            <UserPrivateRoute>
              <DashboardApp />
            </UserPrivateRoute>
          ),
        },
        { 
          path: 'profile', 
          element: (
            <UserPrivateRoute>
              <Profile />
            </UserPrivateRoute>
          ),
        },{ 
          path: 'subscriptions', 
          element: (
            <UserPrivateRoute>
              <Subscription />
            </UserPrivateRoute>
          ),
        },
        { 
          path: 'openaccount', 
          element: (
            <UserPrivateRoute>
              <UserOpenAccount/>
            </UserPrivateRoute>
          ),
        },
],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'verify-email/:token',
      element: <VerfyEmail />,
    }, 
    {
      path: 'resetpassword',
      element: <ResetPassword />,
    },
    , 
    {
      path: 'reset-password/:token',
      element: <ResetPasswordWthToken />,
    },
    {
      path: 'checkout',
      element: <Checkout />,
    },
    {
      path: 'register',
      element: <Register />,
    },{
      path: 'register/campaign/:bonusCode/:email',
      element: <BonusRegister />,
    },
 
    {
      path: '/forexbots',
      element: <Home />,
    }
    ,
    {
      path: '/forexbots/forex-news',
      element: <ForexNews />,
    }
    ,
    {
      path: '/',
      element: <MainPage />,
    }
    ,
    {
      path: '/bot/:slug',
      element: <Cryptobot />,
    },
   
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
}
