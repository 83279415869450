import React from 'react';
import { Tilt } from 'react-tilt';
import config from './config/index.json';

const Features = () => {
  const { features } = config;
  const { title, subtitle, description, items: featuresList } = features;
  
  return (
    <div className="py-12 bg-background" id="Strategy">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
        
          <h1 style={{color:'#234264'}} className='text-3xl font-bold  text-center m-5' id='products'>{title}</h1>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {subtitle}
          </p>
          <p className="mt-4 max-w-2xl text-sm text-gray-500 lg:mx-auto">
            {description}
          </p>
        </div>

        

        <div className="mt-10 grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {featuresList.map((feature) => (
          <Tilt
            key={feature.name}
            className="Tilt"
            options={{ max: 25, speed: 400, scale: 1.05 }}
          >
            <div className="relative p-6 rounded-lg bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-lg transform transition duration-500 hover:shadow-2xl hover:bg-opacity-20 hover:border-2 hover:border-gold max-h-90 h-full">
              <div className="flex items-center">
                <div className="flex-shrink-0 mr-4">
                  <img
                    className="h-12 w-12"
                    src={feature.icon}
                    alt={feature.name}
                  />
                </div>
                <div>
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </div>
              </div>
              <p className="mt-4 text-base text-gray-500">
                {feature.description}
              </p>
            </div>
          </Tilt>
        ))}
      </div>
      
      </div>
    </div>
  );
};

export default Features;
