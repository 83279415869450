import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BotContext } from '../components/BotContext/BotContext';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import NavbarComponent from '../components/NewNav';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import { Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import Navbar from '../components/NewNav';
import UserDashboardNavbar from '../layouts/dashboard copy/DashboardNavbar';

const Container = styled(motion.div)`
  max-width: 900px;
  margin: 0 auto 150px auto;
  padding: 20px 20px 80px 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 50px;
  @media (min-width: 768px) {
    margin-top: 100px;
  }
`;

const BotImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 20px 0;
`;

const Description = styled.p`
  margin-top: 20px;
  font-size: 1.1rem;
  color: #666;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
`;

const RichTextContainer = styled.div`
  margin-top: 10px;
  font-size: 1.1rem;
  color: #666;
  p {
    margin-bottom: 10px;
  }
`;

const VideoContainer = styled.div`
  margin-top: 150px;
  margin-bottom: 150px;
  iframe {
    width: 100%;
    height: 400px;
    border-radius: 8px;
  }
`;

const TabsContainer = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: center;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  background-color: ${(props) => (props.active ? '#007bff' : '#f8f8f8')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
`;

const PricingContainer = styled.section`
  background-color: #f8f8f8;
  padding: 20px 0;
  margin-top: 20px;
`;

const PricingTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const PricingList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const PricingItem = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
`;

const PricingItemTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
`;

const PricingItemFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  font-size: 1rem;
  color: #666;
`;

const PricingItemFeature = styled.li`
  margin-bottom: 10px;
`;

const PricingItemPrice = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
`;

const BuyNowButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const OpenAccountContainer = styled.div`
  background: #fff;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const SignInButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const SendRequestButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const Cryptobot = () => {
  const isUserLoggedIn = localStorage.getItem('name') && localStorage.getItem('email');
  const isUserAdmin = localStorage.getItem('role') 
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const navigate = useNavigate();
  const { slug } = useParams();
  const { selectedBot } = useContext(BotContext);
  const [botData, setBotData] = useState(null);
  const [activeTab, setActiveTab] = useState('rent');
  const token = localStorage.getItem('token');
  const bot = window.localStorage.getItem('selectedBot');

  useEffect(() => {
    if (selectedBot && selectedBot.slug === slug) {
      setBotData(selectedBot);
    } else {
      fetch(`https://traderz.fund:3060/settings/bots/${slug}`)
        .then(response => response.json())
        .then(data => {
          setBotData(data);
        })
        .catch(error => {
          setAlertMessage('Failed to fetch bot data.');
          setAlertSeverity('error');
          setOpen(true);
        });
    }
  }, [slug, selectedBot]);

  const getEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      return `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
    }
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const handleBuyNow = (plan) => {
    if (!token) {
      navigate('/login');
      setAlertMessage('Please sign in to buy this plan.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }
    // Store bot data and selected plan in local storage
    localStorage.setItem('selectedBot', JSON.stringify(botData));
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
    setSelectedPlan(plan);
    navigate('/checkout', { state: { bot: botData, plan } });
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  const handleSendRequest = async () => {
    if (!token) {
      setAlertMessage('No token found, please sign in.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }

    const requestData = {
      bot_id: botData.id,
      token: token,
    };

    try {
      const response = await fetch('https://traderz.fund:3060/settings/openAccount/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setAlertMessage('Request sent successfully!');
        setAlertSeverity('success');
        setOpen(true);
      } else {
        setAlertMessage('Account for this user and bot already exists');
        setAlertSeverity('error');
        setOpen(true);
      }
    } catch (error) {
      setAlertMessage('Error sending request.');
      setAlertSeverity('error');
      setOpen(true);
    }
  };

  const handleSaveAccountNumber = async () => {
    if (!accountNumber) {
      setAlertMessage('Account number cannot be empty.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }

    if (!token) {
      setAlertMessage('No token found, please sign in.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }
    handleSendRequest()

    try {
      const response = await fetch('https://traderz.fund:3060/users/openAccount/updateAccountNumber ', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account_number: accountNumber,
          token,
          bot_id:botData.id
        }),
   
      });

      if (response.ok) {
        setAlertMessage('Account number saved successfully!');
        setAlertSeverity('success');
        setOpen(true);
        setDialogOpen(false);
      } else {
        setAlertMessage('this bot already exist on your account as a singals subscription ,please check your control panel');
        setAlertSeverity('error');
        setOpen(true);
      }
    } catch (error) {
      setAlertMessage('Error saving account number.');
      setAlertSeverity('error');
      setOpen(true);
    }
  };

  const handleEnterLater = () => {
    setDialogOpen(false);
    handleSendRequest()
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    
  };

  if (!botData) {
    return <div>Loading...</div>;
  }

  return (
    <>
     {!isUserLoggedIn &&  <Navbar />}
      {isUserLoggedIn&&isUserAdmin === 'admin' && <DashboardNavbar />}
      {isUserLoggedIn&&isUserAdmin !== 'admin' && <UserDashboardNavbar />}
      <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        <Title>{botData.name}</Title>
        <BotImage src={botData.img} alt={`${botData.name} image`} />
        <Description
          dangerouslySetInnerHTML={{ __html: botData.description || 'No description available' }}
        />

        <SectionTitle>Strategy</SectionTitle>
        <RichTextContainer dangerouslySetInnerHTML={{ __html: botData.strategy }} />

        <SectionTitle>Specifications</SectionTitle>
        <RichTextContainer dangerouslySetInnerHTML={{ __html: botData.spec }} />

        <TabsContainer>
          <TabButton active={activeTab === 'rent'} onClick={() => setActiveTab('rent')}>
            Rent Bot
          </TabButton>
          <TabButton active={activeTab === 'open'} onClick={() => setActiveTab('open')}>
            Signal Subscription
          </TabButton>
        </TabsContainer>

        {activeTab === 'rent' && botData.Packages && (
          <PricingContainer>
            <PricingTitle>Pricing Plans</PricingTitle>
            <PricingList>
              {botData.Packages.map((plan) => (
                <PricingItem key={plan.id}>
                  <PricingItemTitle>{plan.name}</PricingItemTitle>
                  <PricingItemFeatures>
                    {plan.Features && plan.Features.map((feature) => (
                      <PricingItemFeature key={`${plan.id}-${feature.id}`}>
                        {feature.name}
                      </PricingItemFeature>
                    ))}
                  </PricingItemFeatures>
                  <PricingItemPrice>${plan.price}</PricingItemPrice>
                  <BuyNowButton onClick={() => handleBuyNow(plan)}>Buy Now</BuyNowButton>
                </PricingItem>
              ))}
            </PricingList>
          </PricingContainer>
        )}

        {activeTab === 'open' && (
          <OpenAccountContainer>
            {!token ? (
              <SignInButton onClick={handleSignIn}>Sign in to Open Account</SignInButton>
            ) : (
              <SendRequestButton onClick={() => setDialogOpen(true)}>Send Request</SendRequestButton>
            )}
          </OpenAccountContainer>
        )}

        {botData.video && (
          <VideoContainer>
            <SectionTitle>Video</SectionTitle>
            <iframe
              src={getEmbedUrl(botData.video)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoContainer>
        )}
      </Container>

      <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity} variant="filled" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Enter Account Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="accountNumber"
            label="Account Number"
            type="text"
            fullWidth
            variant="outlined"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEnterLater} color="primary">
            Enter Later
          </Button>
          <Button onClick={handleSaveAccountNumber} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cryptobot;
