import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './SliderComponent.css'; 
import campaign from '../Home/img/campain2.png';
import { useNavigate } from 'react-router-dom';

const SliderComponent = () => {
  const navigate = useNavigate();

  const slides = [
    {
      image: campaign,
      text: 'Welcome to Our Service',
      buttonText: 'Learn More',
      link: '/', 
    },
    {
      image: 'https://mudrex.com/blog/wp-content/uploads/2020/07/Blog3-1261x660.png',
      text: 'Explore Our Features',
      buttonText: 'Get Started',
      link: '/', 
    },
    {
      image: 'https://crehana-blog.imgix.net/media/filer_public/35/6e/356ed970-5977-496e-ac95-c1ed2b9c23d6/ventajas_de_los_bots_de_trading.jpg?auto=format&q=50',
      text: 'Join Us Today',
      buttonText: 'Get Started',
      link: '/', 
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    arrows: false,
   
  };

  const handleImageClick = (link) => {
    navigate(link); 
  };

  return (
    <div className="" >

      <Slider {...settings} >
        {slides.map((slide, index) => (
          <div
          
            key={index}
       
            style={{ width: '100%' , height: '100%'}} 
            onClick={(e) => e.preventDefault()} 
          >
            <img src={slide.image} alt={`Slide ${index + 1}`} style={{ width: '100%' , height: '500px'}} />
            <div className="overlay" >
              <div className="content">
              
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
