import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, Typography, Box, Grid, IconButton } from "@mui/material";
import { LocationOn, Phone, Email, Favorite } from "@mui/icons-material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const rightSideRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    // Adjust the height of the right side to match the form's height
    if (formRef.current && rightSideRef.current) {
      rightSideRef.current.style.height = `${formRef.current.offsetHeight}px`;
    }
  }, [formRef.current?.offsetHeight]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://traderz.fund:3060/users/contact-us", formData);
      if (response.status === 200) {
        toast.success("Form submitted successfully!");
      } else {
        toast.error("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }

    // Reset form fields after submission
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: ""
    });
  };

  return (
    <Box p={4} id="contactus" className='max-w-7xl mx-auto '  >
      <Typography style={{color:'#234264'}} variant="h3" gutterBottom align="center">Contact Us</Typography>
      
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Typography variant="h6" align="center">We’d <Favorite color="error" /> love to help!</Typography>
      </Box>
      <Typography variant="body2" color="textSecondary" align="center" mb={4}>
        We like to create things with fun, open-minded people. Feel free to say hello!
      </Typography>
      <Grid container spacing={4}  >
        <Grid item xs={12} md={7}>
          <Box boxShadow={3} p={4} borderRadius={4} ref={formRef} >
            <form onSubmit={handleSubmit}>
              <TextField
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                id="subject"
                name="subject"
                label="Subject"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.subject}
                onChange={handleChange}
                required
              />
              <TextField
                id="message"
                name="message"
                label="Message"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
              />
              <Button type="submit" variant="contained" fullWidth sx={{ backgroundColor: "#234264", "&:hover": { backgroundColor: "#1B3A4F" } }}>
                Submit
              </Button>
            </form>
            <Typography variant="body1" color="textSecondary" align="center" mt={2}>
              We will contact you within 24 hours.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box p={4} borderRadius={4} ref={rightSideRef} display="flex" flexDirection="column" justifyContent="space-around">
            <Box display="flex" alignItems="center" mb={2}>
              <LocationOn style={{ color: '#234264' }} />
              <Typography variant="body1" ml={1} style={{ alignSelf: "center" }}>
                75 Shelton Street, Covent Garden, London, <br /> United Kingdom, WC2H 9JQ., London, UK
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <Phone style={{ color: '#234264' }} />
              <Typography variant="body1" ml={1} style={{ alignSelf: "center" }}>
                <a href="tel:+962795969885" style={{ color: "inherit", textDecoration: "none" }}>
                  +962795969885
                </a>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <Email style={{ color: '#234264' }} />
              <Typography variant="body1" ml={1} style={{ alignSelf: "center" }}>
                <a href="mailto:support@traderz.fund" style={{ color: "inherit", textDecoration: "none" }}>
                  support@traderz.fund
                </a>
              </Typography>
            </Box>
            <hr style={{ width: '50%', textAlign: 'center' }} />
            <Box mt={2}>
              <Typography variant="h6" gutterBottom>Follow Us</Typography>
              <Box display="flex">
                <a
                  target="_blank"
                  className="btn btn-outline-light btn-social"
                  href="https://x.com/traderz_fund"
                  style={{ color: "#1DA1F2", marginRight: "10px" }}
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  target="_blank"
                  className="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/profile.php?id=61563997079027"
                  style={{ color: "#3b5998", marginRight: "10px" }}
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  target="_blank"
                  className="btn btn-outline-light btn-social"
                  href="https://www.youtube.com/channel/UC_TEObR-3lf6oW_WCZFv5kA"
                  style={{ color: "#FF0000", marginRight: "10px" }}
                >
                  <i className="fab fa-youtube" />
                </a>
                <a
                  target="_blank"
                  className="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/traderzz_official/?next=%2F"
                  style={{ color: "#C13584" }}
                >
                  <i className="fab fa-instagram" />
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
};

export default ContactForm;
