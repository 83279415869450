import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import AddBankModal from './modals/AddBankModal';
import AddWalletModal from './modals/AddWalletModal';
import { toast } from 'react-toastify';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  margin: 0;
  padding: 10px 0;
  border-bottom: 2px solid #eee;
  color: #555;
`;

const Input = styled.input`
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
  &:focus {
    outline: none;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: #6c757d;
  &:hover {
    background-color: #5a6268;
  }
`;

const RemoveButton = styled(Button)`
  padding: 6px 12px;
  font-size: 14px;
  background-color: #dc3545;
  &:hover {
    background-color: #c82333;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 6px;
`;

const Settings = () => {
  const [bankDetails, setBankDetails] = useState([]);
  const [walletDetails, setWalletDetails] = useState([]);
  const [newBankDetails, setNewBankDetails] = useState([]);
  const [newWalletDetails, setNewWalletDetails] = useState([]);
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  useEffect(() => {
    fetchBankDetails();
    fetchWalletDetails();
  }, []);

  const fetchBankDetails = async () => {
    const banks = await GetBankAccounts();
    setBankDetails(banks);
  };

  const fetchWalletDetails = async () => {
    const wallets = await GetWalletAccounts();
    setWalletDetails(wallets);
  };

  const GetWalletAccounts = async () => {
    const response = await axios.get('https://traderz.fund:3060/settings/all/wallet');
    return response.data;
  };

  const GetBankAccounts = async () => {
    const response = await axios.get('https://traderz.fund:3060/settings/all/bank');
    return response.data;
  };

  const SaveNewSettings = async (newData) => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: ` ${token}` };
    try {
      await axios.post('https://traderz.fund:3060/settings/new', newData, { headers });
      toast.success('Settings saved successfully!');
      fetchBankDetails();
      fetchWalletDetails();
    } catch (error) {
      toast.error('Failed to save settings. Please try again.');
    }
  };

  const deleteBankDetail = async (id) => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: ` ${token}` };
    try {
      await axios.delete(`https://traderz.fund:3060/settings/delete/payments/${id}`, { headers });
      toast.success('Bank detail deleted successfully!');
      fetchBankDetails();
    } catch (error) {
      toast.error('Failed to delete bank detail. Please try again.');
    }
  };

  const deleteWalletDetail = async (id) => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    try {
      await axios.delete(`https://traderz.fund:3060/settings/delete/payments/${id}`, { headers });
      toast.success('Wallet detail deleted successfully!');
      fetchWalletDetails();
    } catch (error) {
      toast.error('Failed to delete wallet detail. Please try again.');
    }
  };

  const handleBankInputChange = (index, event, isNew = false) => {
    const { name, value, type, checked } = event.target;
    const details = isNew ? [...newBankDetails] : [...bankDetails];
    details[index][name] = type === 'checkbox' ? !checked : value;

    if (isNew) {
      setNewBankDetails(details);
    } else {
      setBankDetails(details);
    }
  };

  const handleWalletInputChange = (index, event, isNew = false) => {
    const { name, value, type, checked } = event.target;
    const details = isNew ? [...newWalletDetails] : [...walletDetails];
    details[index][name] = type === 'checkbox' ? !checked : value;

    if (isNew) {
      setNewWalletDetails(details);
    } else {
      setWalletDetails(details);
    }
  };

  const handleSubmitBanks = async (newBankDetails) => {
    await SaveNewSettings({ bankAccounts: newBankDetails, type: 'bank' });
    setNewBankDetails([]); // Clear new bank details after submission
  };

  const handleSubmitWallets = async (newWalletDetails) => {
    await SaveNewSettings({ walletAccounts: newWalletDetails, type: 'wallet' });
    setNewWalletDetails([]); // Clear new wallet details after submission
  };

  const handleRemoveBankDetails = async (index) => {
    const bankDetail = bankDetails[index];
    if (window.confirm(`Are you sure you want to delete the bank detail: ${bankDetail.bankName}?`)) {
      await deleteBankDetail(bankDetail.id); // Assuming `id` is a property of bank details
    }
  };

  const handleRemoveWalletDetails = async (index) => {
    const walletDetail = walletDetails[index];
    if (window.confirm(`Are you sure you want to delete the wallet detail: ${walletDetail.walletName}?`)) {
      await deleteWalletDetail(walletDetail.id); // Assuming `id` is a property of wallet details
    }
  };

  return (
    <>
      <GlobalStyle />
      <h3 className='text-center  text-primary'>Bank Details and Wallet Settings</h3>
      <Container className='ms-auto me-auto'>
        <SecondaryButton className='ms-3 float-right ' type="button" onClick={() => setIsWalletModalOpen(true)}>
          Add Wallet Details
        </SecondaryButton>
        <Button className='ms-auto float-right' onClick={() => setIsBankModalOpen(true)}>
          Add Bank Details
        </Button>
        <h6 className='mb-5'></h6>
        <form className=' w-100'>
          <SectionTitle>Bank Details</SectionTitle>
          {bankDetails.map((details, index) => (
            <DetailsContainer key={index}>
              <Input
                type="text"
                name="bankName"
                placeholder="Bank Name"
                value={details.bankName}
                onChange={(event) => handleBankInputChange(index, event)}
              />
              <Input
                type="text"
                name="bankIban"
                placeholder="IBAN"
                value={details.bankIban}
                onChange={(event) => handleBankInputChange(index, event)}
              />
              <Input
                type="text"
                name="bankCountry"
                placeholder="Country"
                value={details.bankCountry}
                onChange={(event) => handleBankInputChange(index, event)}
              />
              <Input
                type="text"
                name="bankSwiftCode"
                placeholder="SWIFT Code"
                value={details.bankSwiftCode}
                onChange={(event) => handleBankInputChange(index, event)}
              />
              <CheckboxLabel>
                Show
                <Input
                  type="checkbox"
                  name="isHidden"
                  checked={!details.isHidden}
                  onChange={(event) => handleBankInputChange(index, event)}
                />
              </CheckboxLabel>
              {bankDetails.length > 1 && (
                <RemoveButton type="button" onClick={() => handleRemoveBankDetails(index)}>
                  Remove
                </RemoveButton>
              )}
            </DetailsContainer>
          ))}
        </form>
        <Form onSubmit={handleSubmitWallets}>
          <SectionTitle>Wallet Details</SectionTitle>
          {walletDetails.map((details, index) => (
            <DetailsContainer key={index}>
              <Input
                type="text"
                name="walletName"
                placeholder="Wallet Name"
                value={details.walletName}
                onChange={(event) => handleWalletInputChange(index, event)}
              />
              <Input
                type="text"
                name="walletValue"
                placeholder="Wallet Value"
                value={details.walletValue}
                onChange={(event) => handleWalletInputChange(index, event)}
              />
              <CheckboxLabel>
                Show
                <Input
                  type="checkbox"
                  name="isHidden"
                  checked={!details.isHidden}
                  onChange={(event) => handleWalletInputChange(index, event)}
                />
              </CheckboxLabel>
              {walletDetails.length > 1 && (
                <RemoveButton type="button" onClick={() => handleRemoveWalletDetails(index)}>
                  Remove
                </RemoveButton>
              )}
            </DetailsContainer>
          ))}
        </Form>
      </Container>

      <AddBankModal
        isOpen={isBankModalOpen}
        onRequestClose={() => setIsBankModalOpen(false)}
        onSubmit={handleSubmitBanks}
      />

      <AddWalletModal
        isOpen={isWalletModalOpen}
        onRequestClose={() => setIsWalletModalOpen(false)}
        onSubmit={handleSubmitWallets}
      />
    </>
  );
};

export default Settings;
