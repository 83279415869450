import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Container,
  Button,
  CircularProgress,
  Paper,
  Divider,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockIcon from '@mui/icons-material/Lock';

const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#FFF',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const ProfileField = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const UpdateButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newName, setNewName] = useState('');
  const [password, setPassword] = useState('');
  const [fieldToUpdate, setFieldToUpdate] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch('https://traderz.fund:3060/users/info', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: ` ${token}`,
          },
          
        });

        if (response.ok) {
          const data = await response.json();
          setProfileData(data);
        } else {
          toast.error('Failed to fetch profile data.');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        toast.error('An error occurred while fetching profile data.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleOpenDialog = (field) => {
    setFieldToUpdate(field);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPassword('');
  };

  const handleUpdateProfile = () => {
    // Perform profile update logic here
    handleCloseDialog();
    toast.success('Profile updated successfully!');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!profileData) {
    return <Typography variant="h6">Profile data not available.</Typography>;
  }

  const { name, email, role, status, emailVerified, createdAt } = profileData;

  return (
    <ProfileContainer maxWidth="">
      <ProfileHeader>
        <Avatar sx={{ width: 80, height: 80, marginRight: 2 }}>
          {profileData.img ? (
            <img src={profileData.img} alt={name} style={{ width: '100%', height: '100%' }} />
          ) : (
            <Typography variant="h4">{name.charAt(0)}</Typography>
          )}
        </Avatar>
        <Box>
          <Typography variant="h4">{name}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {email}
          </Typography>
        </Box>
      </ProfileHeader>

      <ProfileDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ProfileField variant="subtitle1">
              <AccountCircleIcon /> Name:
            </ProfileField>
            <Typography variant="body1">{name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProfileField variant="subtitle1">
              <EmailIcon /> Email:
            </ProfileField>
            <Typography variant="body1">{email}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProfileField variant="subtitle1">
              <VpnKeyIcon /> Role:
            </ProfileField>
            <Typography variant="body1">{role}</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <ProfileField variant="subtitle1">
              <LockIcon /> Status:
            </ProfileField>
            <Typography variant="body1">{status}</Typography>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <ProfileField variant="subtitle1">
              <VerifiedUserIcon /> Email Verified:
            </ProfileField>
            <Typography variant="body1">{emailVerified ? 'Yes' : 'No'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProfileField variant="subtitle1">Joind At:</ProfileField>
            <Typography variant="body1">{new Date(createdAt).toLocaleString()}</Typography>
          </Grid>
        </Grid>
      </ProfileDetails>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        Update Profile
      </Typography>

      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              value={newName}
              variant="outlined"
              onChange={(e) => setNewName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleOpenDialog('name')}>
                      <AccountCircleIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={newEmail}
              variant="outlined"
              onChange={(e) => setNewEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleOpenDialog('email')}>
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <UpdateButton variant="contained">Update Profile</UpdateButton>
      </form>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your password to confirm the changes to your {fieldToUpdate}.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateProfile} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ProfileContainer>
  );
};

export default Profile;
