import { useForm, FormProvider } from 'react-hook-form';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function BonusRegisterForm({ email, bonusCode }) {
  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: email || '',
      password: '',
      bonusCode: bonusCode || '',
      mobile: '',
    },
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;
const navigate = useNavigate();
  const onSubmit = async (data) => {
    const fullName = `${data.firstName} ${data.lastName}`;
  
    try {
      const { firstName, lastName, ...requestData } = data;
  
      const response = await axios.post('https://traderz.fund:3060/users/campaign-register', {
        ...requestData,
        name: fullName,
      });
      
      toast.success(response.data.Message);
      toast.info('Congrats! Your account has been created. Now you can login.'); 
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="firstName" label="First name" />
            <RHFTextField name="lastName" label="Last name" />
          </Stack>

          <RHFTextField name="email" label="Email address" disabled />
          <RHFTextField name="mobile" label="Mobile Number"  />
          <RHFTextField name="bonusCode" label="Bonus Code" disabled />

          <RHFTextField
            name="password"
            label="Password"
            type="password"
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
}
