// src/pages/ForgotPassword.js

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Stack, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues: { email: '' },
  });

  const { handleSubmit, register, formState: { errors, isSubmitting, isValid } } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://traderz.fund:3060/users/forgot-password', data);
      toast.success(response.data.message);
  setTimeout(() => {
    navigate('/login'); // Redirect after 3 seconds
  }, 3000);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        <ToastContainer />
        <Typography component="h1" variant="h5" gutterBottom>
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} noValidate className='mt-3'>
          <Stack spacing={3} sx={{ width: '100%' }}>
            <TextField
              {...register('email')}
              label="Email address"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ''}
            />
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            //   disabled={isSubmitting || !isValid}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
}
