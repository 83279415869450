import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Home',
    path: '/forexbots',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Users Manager',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Subscriptions Manager',
    path: '/dashboard/subscriptions',
    icon: getIcon('mdi:calendar-month'),
  },
  {
    title: 'Payment Methods',
    path: '/dashboard/payments',
    icon: getIcon('eva:credit-card-fill'),
  },
  {
    title: 'Signal Subscriptions',
    path: '/dashboard/open-account',
    icon: getIcon('eva:folder-add-fill'),
  },
  {
    title: 'Bots Manager',
    path: '/dashboard/bots',
    icon: getIcon('eva:trending-up-fill'),
  },
  ,
  {
    title: 'Expert Manager',
    path: '/dashboard/expert-manager',
    icon: getIcon('eva:file-text-fill'),
  }
  // ,
  // {
  //   title: 'Blogs Manager',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
];

export default navConfig;
