import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../components/auth/AuthContext';
import axios from 'axios';
import * as Yup from 'yup';
import { Stack, IconButton, InputAdornment, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { toast } from 'react-toastify';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export default function LoginForm() {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [alert,setAlert]=useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await LoginSchema.isValid(formData);
    if (!isValid) {
      setAlert(true);
      setAlertMessage('Please fill in all required fields with valid information.');
      setAlertOpen(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post('https://traderz.fund:3060/users/login', formData);
      const { token, name, email, role ,emailVerified} = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      localStorage.setItem('role', role);
      if (role === 'admin') {
        navigate('/dashboard/app');
      } else if(role === 'user'&&emailVerified){
        navigate('/dashboard/app');
        
        }else if(!emailVerified){
          toast.error('Please verify your email address first');
          setAlert('Please verify your email address first FROM EMAIL LINK');
          window.alert('Please verify your email address first FROM EMAIL LINK');
          return{
      
        }
   
      }
      setIsAuthenticated(true);
    } catch (error) {
      setAlertMessage('Invalid email or password');
      setAlertOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
      {/* {    alert&&    <div className='alert alert-danger text-dark'>{alert?alertMessage:'Please fill in all required fields with valid information.'}</div>
    } */}
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          className="mt-3"
        >
          Login
        </LoadingButton>
      </form>
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Login Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
