import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';

// Define styles for the table
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  approvedStatus: {
    color: 'green',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
  },
});

function OpenAccount() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [account_number, setAccount_number] = useState('');
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    axios.get('https://traderz.fund:3060/settings/bots/openAccount/all', {
      headers: {
        Authorization: ` ${token}`,
      },
    })
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddBrokerInfo = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAccount_number('');
    setSelectedRow(null);
  };

  const handleSave = () => {
    if (selectedRow) {
      const updatedData = { account_number }; 

      axios.put(`https://traderz.fund:3060/settings/openAccount/update/${selectedRow.id}`, { account_number }, {
        headers: {
          Authorization: ` ${token}`,
        },
      })
        .then(response => {
          setData(prevData => prevData.map(item => item.id === selectedRow.id ? { ...item, account_number } : item));
          handleClose();
          toast.success('Account number updated successfully');
        })
        .catch(error => {
          console.error('Error saving data:', error);
        });
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!');
    }, () => {
      toast.error('Failed to copy.');
    });
  };

  return (
    <div>
      <Typography variant="h4" className={classes.title}>Open Requests</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Bot ID</TableCell>
              <TableCell>Bot Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Account Number</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row?.id}</TableCell>
                <TableCell
                  onClick={() => copyToClipboard(row.user_id)}
                  style={{ cursor: 'pointer', color: '#007bff' }}
                >
                  {row?.user_id}
                </TableCell>
                <TableCell>{row?.User?.name}</TableCell>
                <TableCell
                  onClick={() => copyToClipboard(row.bot_id)}
                  style={{ cursor: 'pointer', color: '#007bff' }}
                >
                  {row?.bot_id}
                </TableCell>
                <TableCell>{row?.Bot?.name}</TableCell>
                <TableCell className={row.status === 'Approved' ? classes.approvedStatus : ''}>
                  {row.status}
                </TableCell>
                <TableCell
                  onClick={() => copyToClipboard(row.account_number)}
                  style={{ cursor: 'pointer', color: '#007bff' }}
                >
                  {row.account_number}
                </TableCell>
                <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleAddBrokerInfo(row)}>
                    Add Broker Info
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Broker Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the account number for the selected broker.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Account Number"
            type="text"
            fullWidth
            value={account_number}
            onChange={(e) => setAccount_number(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OpenAccount;
