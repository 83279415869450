import { AuthProvider } from './components/auth/AuthContext';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import './App.css';
import './styles/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BotProvider } from './components/BotContext/BotContext';

export default function App() {
  return (
    <BotProvider>
    
    <AuthProvider>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        
        <Router />
        <ToastContainer/>
      </ThemeProvider>
    </AuthProvider>
    </BotProvider>
  );
}
