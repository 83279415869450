import React, { useState, useEffect } from 'react';
import About from '../components/About';
import Canvas from '../components/Canvas';
import Features from '../components/Features';
import LazyShow from '../components/LazyShow';
import MainHero from '../components/MainHero';
import MainHeroImage from '../components/MainHeroImage';
import Pricing from '../components/Pricing';
import Product from '../components/Product';
import Navbar from '../components/NewNav';
import ContactForm from '../components/ContactForm';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import { ToastContainer } from 'react-toastify';
import UserDashboardNavbar from '../layouts/dashboard copy/DashboardNavbar';

import Modal from './adminpages/modals/MainPopup';

import SliderComponent from '../components/Slidercomponent';
import NewsBar from './NewsBar/NewsBar';



const IndexPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setIsModalOpen(true);
      localStorage.setItem('hasVisited', 'true'); // Store a flag in localStorage
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isUserLoggedIn = localStorage.getItem('name') && localStorage.getItem('email');
  const isUserAdmin = localStorage.getItem('role');

  return (
    <>
    {!isUserLoggedIn && (
      <>
        <Navbar />
      </>
    )}
    
    {isUserLoggedIn && isUserAdmin === 'admin' && (
      <><DashboardNavbar /> <br /><br /><br /><br /><br /><br />
      </>
    )}
    
    {isUserLoggedIn && isUserAdmin !== 'admin' && (
      <>
        <UserDashboardNavbar /><br /><br /><br /><br /><br /><br />
      </>
    )}
    
    <SliderComponent />
    <NewsBar/>
      <br /><br /><br /><br />
      <div className={`bg-background grid gap-y-8 overflow-hidden `}>
      <ToastContainer/>
        <div className={`relative bg-background `}>
          <div className="max-w-7xl mx-auto">
            <div className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}>
              <MainHero />
            </div>
          </div>
          <MainHeroImage />
        </div>
        
        <Canvas />
        <LazyShow>
          <>
            <Product />
            <Canvas />
          </>
        </LazyShow>
        <LazyShow>
          <>
            <Features />
            <Canvas />
          </>
        </LazyShow>
        <LazyShow>
          <>
            <ContactForm />
            <Canvas />
            <About />
          </>
        </LazyShow>
      </div>

      {isModalOpen && <Modal onClose={handleCloseModal} isModalOpen={isModalOpen}/>} {/* Render the Modal */}
    </>
  );
};

export default IndexPage;
