import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';  
import axios from 'axios';
import { useState } from 'react';
import ConfirmationModal from '../../../pages/adminpages/modals/ConfirmationModal';
import { toast } from 'react-toastify';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

ShopProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  GetAllProducts: PropTypes.func.isRequired, // Function to refresh data
};

export default function ShopProductCard({ GetAllProducts, product }) {
  const { id, name, img, status, Packages, slug } = product;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const token = window.localStorage.getItem('token');
  
  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`https://traderz.fund:3060/settings/bots/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      GetAllProducts();
      toast.success('Product deleted successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error('Failed to delete product. Please try again.');
    }
    setConfirmationModalOpen(false);
  };
  
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <ProductImgStyle alt={slug} src={img} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to={`/dashboard/cryptobot/${id}/${slug}`} color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <small>{status ? 'Active' : 'Inactive'}</small>
          <Button component={RouterLink} to={`/dashboard/bots/update/${slug}`} variant="outlined">
            Update
          </Button>
          <Button onClick={() => setConfirmationModalOpen(true)} variant="contained" color="error">
            Delete
          </Button>
        </Stack>
      </Stack>

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onCancel={() => setConfirmationModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </Card>
  );
}
